export default {
  submitPost: 'createPost:submit',
  updatePost: 'createPost:update',
  openCreatePostModal: 'createPost:openModal',
  addComment: 'comments:added',
  deleteComment: 'comments:deleted',
  pageCompanyClicked: 'pageCompany:clicked',
  messageUpdated: 'message:updated',
  deleteHighlight: 'highlightPost:deleted',
  scrollToTopFeedList: 'feedList:scrollToTop',
  refetchSearchWithCurrentEntityId: 'refetchSearchWithCurrentEntityId',
};
