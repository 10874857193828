import { jobStatusKeys, removeEmptyFromObject, routeNames } from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { searchFilterQueryParams } from '@shared/constants/search';
import type { PartialRecord } from '@lobox/utils';
import type {
  SearchFiltersQueryParamsType,
  SearchFiltersValueType,
} from '@shared/types/search';
import useClientRouter from '@shared/utils-pkg/hooks/useClientRouter';

type SearchType = PartialRecord<
  SearchFiltersQueryParamsType,
  SearchFiltersValueType
> & { pathname?: string };

const searchJobsDefaultParams = {
  jobs: isBusinessApp
    ? {
        [searchFilterQueryParams.status]: jobStatusKeys.open,
      }
    : {
        [searchFilterQueryParams.searchGroupType]: 'ALL',
      },
} as any;

const useNavigateSearchPage = () => {
  const { push } = useClientRouter({});

  const navigate = (search: SearchType) => {
    const { pathname, ...resetSearch } = search;
    const routePathname = pathname || routeNames.searchJobs;

    const qParams = removeEmptyFromObject({
      ...(searchJobsDefaultParams?.[pathname] || {}),
      ...(resetSearch || {}),
    });
    const urlParams = new URLSearchParams(qParams).toString();
    // TODO: it won't work, we should find a solution maybe pushState
    return push({
      pathname: routePathname,
      search: urlParams,
    });
  };

  return navigate;
};

export default useNavigateSearchPage;
