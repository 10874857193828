import { QueryKeys, geoApi, useReactQuery } from '@shared/utils-pkg';
import useGetAppObject from './useGetAppObject';

export function useAuthCountry() {
  const { authUser } = useGetAppObject();
  const countryCode = authUser?.location?.countryCode;
  const { data: authCountry } = useReactQuery<any>({
    action: {
      apiFunc: geoApi.getCountryByCode,
      key: [QueryKeys.discoverPlace, countryCode],
      params: {
        countryCode,
      },
    },
    config: {
      enabled: !!countryCode,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 60 * 60 * 1000,
    },
  });
  return authCountry;
}
