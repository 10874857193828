export const searchFilterQueryParams = {
  query: 'query',
  searchEntity: 'searchEntity',
  searchGroupType: 'searchGroupType',
  dateRangeType: 'dateRangeType',
  workPlaceTypes: 'workPlaceTypes',
  experienceLevels: 'experienceLevels',
  titles: 'titles',
  categoryIds: 'categoryIds',
  employmentTypes: 'employmentTypes',
  currentEntityId: 'currentEntityId',
  pageIds: 'pageIds',
  sortBy: 'sortBy',
  cities: 'cities',
  skills: 'skills',
  languageIds: 'languageIds',
  jobBenefits: 'jobBenefits',
  salaryPeriod: 'salaryPeriod',
  salaryCurrencyId: 'salaryCurrencyId',
  minSalary: 'minSalary',
  maxSalary: 'maxSalary',
  salaryRange: 'salaryRange',
  applyAtLobox: 'applyAtLobox',
  countryId: 'countryId',
  jobGroupStatus: 'jobGroupStatus',
  creatorIds: 'creatorIds',
  status: 'status',
  hostedBy: 'hostedBy',
  placeId: 'placeId',
  placeTitle: 'placeTitle',
  occupationId: 'occupationId',
  isPlaceTitleFiltered: 'isPlaceTitleFiltered',
  experiencesAtCompanyIds: 'experiencesAtCompanyIds',
  educatedAtSchoolIds: 'educatedAtSchoolIds',
  memberSince: 'memberSince',
  occupationNames: 'occupationNames',
  educationDegree: 'educationDegree',
  industryIds: 'industryIds',
  skillIds: 'skillIds',
  datePosted: 'datePosted',
  postType: 'type',
  postedByUserIds: 'postedByUserIds',
  categories: 'categories',
  companySizes: 'companySizes',
  scope: 'scope',
  page: 'page',
  hashtags: 'hashtags',
  postedBy: 'postedBy',
  establishmentDate: 'establishmentDate',
  relatedPageIds: 'relatedPageIds',
  mentionedUserIds: 'mentionedUserIds',
} as const;

export const searchGroupTypes = {
  ALL: 'ALL',
  APPLIED: 'APPLIED',
  SAVED: 'SAVED',
  POPULAR: 'POPULAR',
  TOP_SUGGESTION: 'TOP_SUGGESTION',
  FOLLOWERS: 'FOLLOWERS',
  FOLLOWINGS: 'FOLLOWINGS',
  INCOMING_REQUESTS: 'INCOMING_REQUESTS',
  PENDING_REQUESTS: 'PENDING_REQUESTS',
} as const;
