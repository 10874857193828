import { useCallback, useMemo } from 'react';
import { encoder, event } from '@lobox/utils';
import { isEmptyObjectValues } from '@lobox/utils';
import { isNumber, uniqBy } from 'lodash';
import type {
  SearchFiltersQueryParamsType,
  SearchFiltersValueType,
} from '@shared/types/search';
import useSearchFiltersFields from './useSearchFiltersFields';
import {
  searchGroupTypes,
  searchFilterQueryParams,
} from '@shared/constants/search';
import { useSearchDispatch } from '@shared/contexts/search/search.provider';
import useClientRouter from '@shared/utils-pkg/hooks/useClientRouter';
import useSearchQueries from './useSearchQueries';
import useDynamicFilters from './useDynamicFilters';
import { useQueryClient } from '@tanstack/react-query';
import { mutableStore } from '@shared/constants/mutableStore';
import eventKeys from '@shared/constants/event-keys';

const useSearchFilters = () => {
  const { push, replace } = useClientRouter({});
  const { groups } = useSearchFiltersFields();
  const dynamicFilters = useDynamicFilters();
  const searchDispatch = useSearchDispatch();
  const queryClient = useQueryClient();

  const {
    occupationId,
    currentEntityId,
    isPlaceTitleFiltered,
    searchGroupType,
  } = useSearchQueries();

  const searchFilters = useMemo(() => {
    const qParams = groups?.reduce((prev: any, curr: any) => {
      const val = curr.getValue();
      const isEmpty = isEmptyObjectValues({
        val,
      });
      return isEmpty
        ? prev
        : {
            ...prev,
            [curr.name]: decodeURIComponent(val),
          };
    }, {});

    qParams.occupationId = occupationId ?? undefined;
    qParams.currentEntityId = currentEntityId ?? undefined;
    qParams.isPlaceTitleFiltered = isPlaceTitleFiltered ?? undefined;

    return qParams;
  }, [groups, occupationId, currentEntityId, searchGroupType]);

  const setFilters = useCallback(
    (variables: Record<any, any>, removeCurrentEntityId?: boolean) => {
      const obj = { ...variables } as Record<
        SearchFiltersQueryParamsType,
        SearchFiltersValueType
      >;

      Object.keys(obj)?.forEach((_key) => {
        const prop = _key as SearchFiltersQueryParamsType;
        obj[prop] = getValue(obj[prop], prop);
      });

      let _searchFilters = { ...obj } as any;
      if (
        !obj[searchFilterQueryParams.searchGroupType] ||
        obj[searchFilterQueryParams.searchGroupType] !== searchGroupTypes.ALL
      ) {
        _searchFilters[searchFilterQueryParams.searchGroupType] =
          searchGroupTypes.ALL;
      }
      if (!obj[searchFilterQueryParams.currentEntityId]) {
        _searchFilters[searchFilterQueryParams.currentEntityId] = undefined;
        _searchFilters[searchFilterQueryParams.page] = 0;
      }

      const _searchGroupType =
        _searchFilters[searchFilterQueryParams.searchGroupType] ||
        searchGroupType;

      const isSameCategory =
        _searchFilters[searchFilterQueryParams.searchGroupType] ===
        searchFilters[searchFilterQueryParams.searchGroupType];

      if (
        !!_searchGroupType &&
        _searchGroupType !== searchGroupTypes.ALL &&
        !isPlaceTitleFiltered &&
        !isSameCategory
      ) {
        _searchFilters[searchFilterQueryParams.placeTitle] = undefined;
        _searchFilters[searchFilterQueryParams.placeId] = undefined;
        _searchFilters[searchFilterQueryParams.countryId] = undefined;
      }
      if (removeCurrentEntityId) {
        _searchFilters[searchFilterQueryParams.currentEntityId] = undefined;
      }
      _searchFilters[searchFilterQueryParams.query] = encoder(
        _searchFilters[searchFilterQueryParams.query] || ''
      );

      push({
        search: new URLSearchParams(
          JSON.parse(JSON.stringify(_searchFilters))
        ).toString(),
      });
    },
    [searchFilters, push]
  );

  const setFilter = useCallback(
    (key: string, value: any, isReplace?: boolean) => {
      let _searchFilters = { ...searchFilters };
      _searchFilters = { ..._searchFilters, [key]: getValue(value) };
      if (key !== searchFilterQueryParams.currentEntityId) {
        _searchFilters[searchFilterQueryParams.currentEntityId] = undefined;
        if (key !== searchFilterQueryParams.page) {
          _searchFilters[searchFilterQueryParams.page] = 0;
        }
      }

      if (
        key !== searchFilterQueryParams.searchGroupType &&
        key !== searchFilterQueryParams.currentEntityId
      ) {
        _searchFilters[searchFilterQueryParams.searchGroupType] =
          searchGroupTypes.ALL;
      }

      const _searchGroupType =
        _searchFilters[searchFilterQueryParams.searchGroupType] ||
        searchGroupType;

      const isSameCategory =
        _searchFilters[searchFilterQueryParams.searchGroupType] ===
        searchFilters[searchFilterQueryParams.searchGroupType];

      if (
        !!_searchGroupType &&
        _searchGroupType !== searchGroupTypes.ALL &&
        !isPlaceTitleFiltered &&
        !isSameCategory
      ) {
        _searchFilters[searchFilterQueryParams.placeTitle] = undefined;
        _searchFilters[searchFilterQueryParams.placeId] = undefined;
        _searchFilters[searchFilterQueryParams.countryId] = undefined;
      }

      if (key !== searchFilterQueryParams.currentEntityId) {
        _searchFilters[searchFilterQueryParams.currentEntityId] = undefined;
      }

      _searchFilters[searchFilterQueryParams.query] = encoder(
        _searchFilters[searchFilterQueryParams.query] || ''
      );

      if (isReplace) {
        replace({
          search: new URLSearchParams(
            JSON.parse(JSON.stringify(_searchFilters))
          ).toString(),
        });
      } else {
        push({
          search: new URLSearchParams(
            JSON.parse(JSON.stringify(_searchFilters))
          ).toString(),
        });
      }
    },
    [searchFilters, push]
  );

  const resetFilters = useCallback(() => {
    // Do not remove the inputs
    let filters = {
      [searchFilterQueryParams.query]:
        searchFilters[searchFilterQueryParams.query],
      [searchFilterQueryParams.placeTitle]:
        searchFilters[searchFilterQueryParams.placeTitle],
      [searchFilterQueryParams.placeId]:
        searchFilters[searchFilterQueryParams.placeId],
    };
    queryClient.invalidateQueries(mutableStore.searchQueryKey);
    push({
      search: new URLSearchParams(
        JSON.parse(JSON.stringify(filters))
      ).toString(),
    });
  }, [[push]]);

  const resetFilter = useCallback(
    (key: string) => {
      setFilter(key, undefined);
    },
    [setFilter]
  );

  const addToDynamicFilters = useCallback(
    (variables: any) => {
      const _dynamicFilters = { ...dynamicFilters };
      if (Object.keys(dynamicFilters || {})) {
        Object?.keys(_dynamicFilters)?.forEach((key) => {
          if (!(key in variables)) return;
          variables[key] = variables[getDynamicFilterEquivalentName(key)];
          const oldValues = dynamicFilters[key];
          const newValues = variables[key];
          if (Array.isArray(newValues) && newValues?.length > 0) {
            _dynamicFilters[key] = uniqBy(
              [...newValues, ...(oldValues || [])],
              'value'
            );
          }
        });
      }

      searchDispatch({
        type: 'SET_SEARCH_DYNAMIC_FILTERS',
        payload: _dynamicFilters,
      });
      return variables;
    },
    [dynamicFilters, searchDispatch]
  );

  const addToDynamicFiltersAndSetFilter = useCallback(
    (variables: any) => {
      const vars = addToDynamicFilters(variables);
      setFilters(vars);
    },
    [setFilters]
  );

  const searchFiltersBackend = useMemo(() => {
    const _searchFilters = JSON.parse(
      JSON.stringify(searchFilters)
    ) as typeof searchFilters;
    Object.keys(searchFilters)?.forEach((key) => {
      const value = _searchFilters[key];

      const isEmpty = isEmptyObjectValues({
        val: value,
      });

      if (key === searchFilterQueryParams.salaryRange) {
        const values = JSON.parse(value);

        delete _searchFilters['salaryRange'];
        if (!!values?.currency?.id)
          _searchFilters['salaryCurrencyId'] = values?.currency?.id;
        if (!!values?.salaryPeriod)
          _searchFilters['salaryPeriod'] = values?.salaryPeriod?.value;
        if (isNumber(values?.salaryRange?.[0]))
          _searchFilters['minSalary'] = values?.salaryRange?.[0];
        if (isNumber(values?.salaryRange?.[1]))
          _searchFilters['maxSalary'] = values?.salaryRange?.[1];
      }
      if (isEmpty || !Array.isArray(value)) return;
      _searchFilters[key] = value.join(',');
    });

    return _searchFilters;
  }, [searchFilters]);

  const refetchWithCurrentEntityId = () => {
    event.trigger(eventKeys.refetchSearchWithCurrentEntityId);
  };

  return {
    searchFilters,
    resetFilters,
    resetFilter,
    setFilters,
    setFilter,
    addToDynamicFiltersAndSetFilter,
    searchFiltersBackend,
    getLabelOrValue,
    refetchWithCurrentEntityId,
  };
};

export default useSearchFilters;

function getDynamicFilterEquivalentName(name: string): string {
  if (name === 'categories') return 'categoryIds';
  if (name === 'pages') return 'pageIds';
  if (name === 'languages') return 'languageIds';

  return name;
}

function getValue(
  val: any,
  prop?: SearchFiltersQueryParamsType
): string | string[] {
  if (typeof val === 'string') return val as string;
  if (Array.isArray(val)) {
    const _prop = getLabelOrValue(prop as any);
    return val?.map((item) => item[_prop] || item?.id || item?.value || item);
  }

  return val?.value || val;
}

function getLabelOrValue(
  itemName: SearchFiltersQueryParamsType
): 'label' | 'value' {
  if (['skills', 'titles'].includes(itemName)) return 'label';
  return 'value';
}
