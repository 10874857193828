import { useMemo } from 'react';
import { useLocation } from '@lobox/utils';

type ISearchEntity = 'posts' | 'pages' | 'people' | 'jobs';

const useSearchEntity = (): ISearchEntity => {
  const { pathname } = useLocation();

  const pathEntity = pathname?.split('/')?.[2];
  const searchEntity = useMemo(() => {
    const validEntities: ISearchEntity[] = ['jobs', 'people', 'pages', 'posts'];
    if (validEntities.includes(pathEntity as ISearchEntity)) {
      return pathEntity as ISearchEntity;
    }
    return 'jobs' as ISearchEntity;
  }, [pathEntity]);

  return searchEntity;
};

export default useSearchEntity;
