import classes from './useSearchFiltersFields.module.scss';
import {
  collectionToObjectByKey,
  db,
  Endpoints,
  geoApi,
  hereApiResponseNormalizer,
  jobsDb,
  lookupNormalizer,
  lookupResponseNormalizer,
  searchEndPoints,
  skillsResponseNormalizer,
  suggestObjects,
  useTranslation,
} from '@lobox/utils';
import { isBusinessApp } from '@shared/utils/getAppEnv';
import { searchFilterQueryParams } from '@shared/constants/search';
import useSearchEntity from './useSearchEntity';
import useSearchQueries from './useSearchQueries';
import { useAuthCountry } from './useAuthCountry';
import { searchBenefits } from '@shared/utils-pkg/api/lookup';
import lookup from '@shared/utils-pkg/constants/servicesEndpoints/services/lookup';
import { makeCityValue } from '@shared/utils/makeCityValue';

// Filters which are not dynamic:
// searchFilterQueryParams.searchEntity
// searchFilterQueryParams.status
// searchFilterQueryParams.searchGroupType
// searchFilterQueryParams.jobGroupStatus
// searchFilterQueryParams.scope

const { jobSearchOptions } = jobsDb;

const searchEntitiesObject = collectionToObjectByKey(
  jobSearchOptions.searchEntities
);

const useSearchFiltersFields = () => {
  const {
    searchGroupType,
    isAllFilter,
    isSavedFilter,
    jobStatus,
    placeTitle,
    placeId,
    jobTitles,
    creators,
    pages,
    relatedPages,
    cities,
    industries,
    languages,
    categories,
    benefits,
    employmentTypes,
    experienceLevels,
    workPlaceTypes,
    datePosted,
    hashtags,
    visibleCategory,
    companySizes,
    pageTypes,
    isPosts,
    skills,
    salaryRange,
    postedByOptions,
    postTypes,
    sortByOptions,
    memberSinceOptions,
    establishmentDateOptions,
    mentions,
    getQueryValue,
  } = useSearchQueries();
  const authCountry = useAuthCountry();
  const searchEntity = useSearchEntity();

  const { t } = useTranslation();
  const groups = (() => {
    const SEARCH_ENTITY = {
      name: searchFilterQueryParams.searchEntity,
      cp: 'list',
      options: jobSearchOptions.searchEntities,
      hiddenInHeader: false,
      alwaysShowInHeader: true,
      hiddenInForm: true,
      getValue: () => searchEntity,
      label: searchEntitiesObject[searchEntity]?.label,
      divider: {
        className: classes.groupDivider,
      },
    };
    const QUERY = {
      name: searchFilterQueryParams.query,
      cp: 'input',
      hiddenInHeader: true,
      hiddenInForm: true,
      getValue: () =>
        decodeURIComponent(getQueryValue(searchFilterQueryParams.query) || ''),
      divider: {
        className: classes.groupDivider,
      },
    };
    const PAGE_NUMBER = {
      name: searchFilterQueryParams.page,
      cp: 'input',
      hiddenInHeader: true,
      hiddenInForm: true,
      getValue: () => getQueryValue(searchFilterQueryParams.page) || 0,
      divider: {
        className: classes.groupDivider,
      },
    };
    const PLACE_TITLE = {
      name: searchFilterQueryParams.placeTitle,
      cp: 'input',
      hiddenInHeader: true,
      hiddenInForm: true,
      getDefaultValue: () =>
        isAllFilter && !isBusinessApp ? authCountry?.title : undefined,
      getValue: () =>
        !placeTitle && isAllFilter
          ? isBusinessApp
            ? undefined
            : authCountry?.title
          : placeTitle,
      divider: {
        className: classes.groupDivider,
      },
    };
    const PLACE_ID = {
      name: searchFilterQueryParams.placeId,
      cp: 'input',
      hiddenInHeader: true,
      hiddenInForm: true,
      getDefaultValue: () =>
        isAllFilter && !isBusinessApp ? authCountry?.id : undefined,
      getValue: () =>
        !placeTitle && isAllFilter
          ? isBusinessApp
            ? undefined
            : authCountry?.id
          : placeId,
      divider: {
        className: classes.groupDivider,
      },
    };
    const COUNTRYID = {
      name: searchFilterQueryParams.countryId,
      cp: 'input',
      hiddenInHeader: true,
      hiddenInForm: true,
      getValue: () => authCountry?.title?.id,
      divider: {
        className: classes.groupDivider,
      },
    };

    const SEARCH_GROUP_TYPE_BUSINESS = {
      name: searchFilterQueryParams.status,
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      options: jobSearchOptions.searchGroupType(isBusinessApp),
      hiddenInForm: true,
      alwaysShowInHeader: true,
      label: t('status'),
      defaultValue: 'OPEN',
      getValue: () => jobStatus,
      divider: {
        className: classes.groupDivider,
      },
    };
    const SEARCH_GROUP_TYPE_USER = {
      name: searchFilterQueryParams.searchGroupType,
      cp: 'radioGroup',
      options: jobSearchOptions.searchGroupType(isBusinessApp),
      hiddenInHeader: true,
      hiddenInForm: true,
      getValue: () => searchGroupType,
      divider: {
        className: classes.groupDivider,
      },
    };
    const JOB_GROUP_STATUS = {
      name: searchFilterQueryParams.jobGroupStatus,
      cp: 'radioGroup',
      options: isSavedFilter
        ? jobSearchOptions.savedJobsGroupStatus
        : jobSearchOptions.appliedJobGroupStatus,
      hiddenInHeader: true,
      hiddenInForm: true,
      label: t('status'),
      getValue: () => getQueryValue(searchFilterQueryParams.jobGroupStatus),
      divider: {
        className: classes.groupDivider,
      },
    };
    const SORT_BY = {
      formGroup: {
        color: 'smoke_coal',
        title: t('sort_by'),
        className: classes.header,
      },
      cp: 'radioGroup',
      name: searchFilterQueryParams.sortBy,
      options: sortByOptions,
      label: t('sort_by'),

      getValue: () =>
        getQueryValue(searchFilterQueryParams.sortBy) ||
        (isPosts ? 'MOST_RECENT' : 'MOST_RELEVANT'),
      hiddenInHeader:
        !getQueryValue(searchFilterQueryParams.sortBy) ||
        getQueryValue(searchFilterQueryParams.sortBy) ===
          (isPosts ? 'MOST_RECENT' : 'MOST_RELEVANT'),
      alwaysShowInHeader:
        !!getQueryValue(searchFilterQueryParams.sortBy) &&
        getQueryValue(searchFilterQueryParams.sortBy) !==
          (isPosts ? 'MOST_RECENT' : 'MOST_RELEVANT'),

      divider: {
        className: classes.groupDivider,
      },
    };

    const MEMBER_SINCE = {
      formGroup: {
        color: 'smoke_coal',
        title: t('member_since'),
        className: classes.header,
      },
      cp: 'radioGroup',
      name: searchFilterQueryParams.memberSince,
      divider: {
        className: classes.groupDivider,
      },
      options: memberSinceOptions,
      label: t('member_since'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.memberSince) || 'ANY_TIME',
      hiddenInHeader: false,
      alwaysShowInHeader: true,
      isDefaultValue:
        !getQueryValue(searchFilterQueryParams.memberSince) ||
        getQueryValue(searchFilterQueryParams.memberSince) === 'ANY_TIME',
    };
    const ESTABLISHMENT_DATE = {
      formGroup: {
        color: 'smoke_coal',
        title: t('establishment_date'),
        className: classes.header,
      },
      label: t('establishment_date'),
      cp: 'radioGroup',
      name: searchFilterQueryParams.establishmentDate,
      divider: {
        className: classes.groupDivider,
      },
      options: establishmentDateOptions,
      getValue: () =>
        getQueryValue(searchFilterQueryParams.establishmentDate) || 'ANY_TIME',
      hiddenInHeader: false,
      alwaysShowInHeader: true,
      isDefaultValue:
        !getQueryValue(searchFilterQueryParams.establishmentDate) ||
        getQueryValue(searchFilterQueryParams.establishmentDate) === 'ANY_TIME',
    };
    const DATE_POSTED = {
      formGroup: {
        color: 'smoke_coal',
        title: t('date_posted'),
        className: classes.header,
      },
      cp: 'radioGroup',
      name: searchFilterQueryParams.datePosted,
      divider: {
        className: classes.groupDivider,
      },
      options: datePosted,
      label: t('date_posted'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.datePosted) || 'ANY_TIME',
      hiddenInHeader: false,
      alwaysShowInHeader: true,
      isDefaultValue:
        !getQueryValue(searchFilterQueryParams.datePosted) ||
        getQueryValue(searchFilterQueryParams.datePosted) === 'ANY_TIME',
    };
    const CATEGORY = {
      formGroup: {
        color: 'smoke_coal',
        title: t('category'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.categoryIds,
      divider: {
        className: classes.groupDivider,
      },
      options: categories,
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: Endpoints.App.Common.getIndustry,
        normalizer: lookupResponseNormalizer,
      },
      label: t('category'),
      placeholder: t('search_category'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.categoryIds, 'array'),
      alwaysShowInHeader: true,
    };
    const EXPERIENCE_LEVEL = {
      formGroup: {
        color: 'smoke_coal',
        title: t('exp_level'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.experienceLevels,
      divider: {
        className: classes.groupDivider,
      },
      options: experienceLevels,
      label: t('exp_level'),
      placeholder: t('search_exp_level'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.experienceLevels, 'array'),
      alwaysShowInHeader: true,
    };
    const EMPLOYMENT_TYPE = {
      formGroup: {
        color: 'smoke_coal',
        title: t('j_type'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.employmentTypes,
      divider: {
        className: classes.groupDivider,
      },
      options: employmentTypes,
      label: t('j_type'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.employmentTypes, 'array'),
      alwaysShowInHeader: true,
    };
    const JOB_MODEL = {
      formGroup: {
        color: 'smoke_coal',
        title: t('job_model'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.workPlaceTypes,
      divider: {
        className: classes.groupDivider,
      },
      options: workPlaceTypes,
      label: t('job_model'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.workPlaceTypes, 'array'),
      alwaysShowInHeader: true,
    };
    const POST_TYPE = {
      formGroup: {
        color: 'smoke_coal',
        title: t('post_type'),
        className: classes.header,
      },
      cp: 'radioGroup',
      name: searchFilterQueryParams.postType,
      divider: {
        className: classes.groupDivider,
      },
      // no sort based on alphabets
      options: postTypes,
      label: t('post_type'),
      alwaysShowInHeader: true,
      getValue: () => getQueryValue(searchFilterQueryParams.postType),
    };

    const TITLES = {
      formGroup: {
        color: 'smoke_coal',
        title: t('j_title'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.titles,
      divider: {
        className: classes.groupDivider,
      },
      options: jobTitles,
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: Endpoints.App.Common.getOccupations,
        normalizer: lookupResponseNormalizer,
      },
      label: t('j_title'),
      placeholder: t('search_j_title'),
      isCustomEntryAllowed: true,
      getValue: () => getQueryValue(searchFilterQueryParams.titles, 'array'),
      alwaysShowInHeader: true,
    };

    const LOCATION = {
      formGroup: {
        color: 'smoke_coal',
        title: t('location'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.cities,
      options: cities,
      label: t('locations'),
      placeholder: t('search_location'),
      getValue: () => getQueryValue(searchFilterQueryParams.cities, 'array'),
      alwaysShowInHeader: true,
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        apiFunc: geoApi.suggestCity,
        normalizer: (items: any) =>
          items?.map((item: any) => ({
            label: item?.label,
            value: makeCityValue(item?.label, item?.cityCode),
          })),
      },
    };
    const POSTED_BY = {
      formGroup: {
        color: 'smoke_coal',
        title: t('posted_by'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      cp: 'checkBoxGroup',
      name: searchFilterQueryParams.postedBy,
      options: postedByOptions,
      label: t('posted_by'),
      getValue: () => getQueryValue(searchFilterQueryParams.postedBy, 'array'),
      alwaysShowInHeader: true,
    };
    const HASHTAGS = {
      formGroup: {
        color: 'smoke_coal',
        title: t('hashtags'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.hashtags,
      options: hashtags,
      label: t('hashtags'),
      placeholder: t('search_hashtag'),
      getValue: () => getQueryValue(searchFilterQueryParams.hashtags, 'array'),
      alwaysShowInHeader: true,
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: searchEndPoints.suggestHashtag,
        normalizer: (res: any) =>
          res?.content?.map((value: any) => ({ value, label: `#${value}` })),
      },
    };

    const PAGE_TYPES = pageTypes?.length && {
      formGroup: {
        color: 'smoke_coal',
        title: t('page_type'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.categories,
      label: t('page_type'),
      divider: {
        className: classes.groupDivider,
      },
      options: pageTypes,
      getValue: () =>
        getQueryValue(searchFilterQueryParams.categories, 'array'),
      alwaysShowInHeader: true,
    };
    const COMPANY_SIZE = companySizes?.length && {
      formGroup: {
        color: 'smoke_coal',
        title: t('page_size'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.companySizes,
      label: t('page_size'),
      divider: {
        className: classes.groupDivider,
      },
      options: companySizes,
      getValue: () =>
        getQueryValue(searchFilterQueryParams.companySizes, 'array'),
      alwaysShowInHeader: true,
    };

    const LANGUAGES = {
      formGroup: {
        color: 'smoke_coal',
        title: t('languages'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      name: searchFilterQueryParams.languageIds,
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      label: t('languages'),
      placeholder: t('search_language'),
      options: languages,
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: Endpoints.App.Common.getLanguages,
        normalizer: lookupResponseNormalizer,
      },
      getValue: () =>
        getQueryValue(searchFilterQueryParams.languageIds, 'array'),
      alwaysShowInHeader: true,
    };

    const DGREES = benefits?.length && {
      formGroup: {
        color: 'smoke_coal',
        title: t('degree'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.educationDegree,
      options: benefits,
      label: t('degree'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.educationDegree, 'array'),
    };

    const SALARY = !!salaryRange && {
      formGroup: {
        color: 'smoke_coal',
        title: t('salary_range'),
        className: classes.header,
      },
      divider: {
        className: classes.groupDivider,
      },
      cp: 'salaryPicker',
      withConfirmation: false,
      name: searchFilterQueryParams.salaryRange,
      data: salaryRange,
      getValue: () => getQueryValue(searchFilterQueryParams.salaryRange),
      label: t('salary_range'),
    };

    const CREATED_BY = {
      formGroup: {
        color: 'smoke_coal',
        title: t('created_by'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.creatorIds,
      divider: {
        className: classes.groupDivider,
      },
      options: creators,
      label: t('creators'),
      placeholder: t('search_creator'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.creatorIds, 'array'),
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        apiFunc: suggestObjects,
        normalizer: (response: any) =>
          response?.content?.map(({ id, fullName, type, username }: any) => ({
            value: id,
            label: fullName,
            type,
            helperText: username,
          })),
      },
    };

    const MENTIONS = {
      formGroup: {
        color: 'smoke_coal',
        title: t('mentions'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.mentionedUserIds,
      divider: {
        className: classes.groupDivider,
      },
      options: mentions,
      label: t('mentions'),
      placeholder: t('search_mentions'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.mentionedUserIds, 'array'),
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        apiFunc: suggestObjects,
        normalizer: (response: any) =>
          response?.content?.map(({ id, fullName, type, username }: any) => ({
            value: id,
            label: fullName,
            type,
            helperText: username,
          })),
      },
    };

    const PAGES = {
      formGroup: {
        color: 'smoke_coal',
        title: t('page'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.pageIds,
      divider: {
        className: classes.groupDivider,
      },
      options: pages,
      label: t('page'),
      placeholder: t('search_company'),
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: `${Endpoints.App.Common.suggestPlace}`,
        normalizer: hereApiResponseNormalizer,
      },
      hiddenInBusiness: true,
      getValue: () => getQueryValue(searchFilterQueryParams.pageIds, 'array'),
    };
    const SCHOOLS = {
      formGroup: {
        color: 'smoke_coal',
        title: t('schools'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      withConfirmation: false,
      name: searchFilterQueryParams.educatedAtSchoolIds,
      divider: {
        className: classes.groupDivider,
      },
      options: pages,
      label: t('school'),
      placeholder: t('search_school'),
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: `${Endpoints.App.Common.suggestPlace}`,
        normalizer: hereApiResponseNormalizer,
      },
      hiddenInBusiness: true,
      getValue: () =>
        getQueryValue(searchFilterQueryParams.educatedAtSchoolIds, 'array'),
    };

    const SKILLS = {
      formGroup: {
        color: 'smoke_coal',
        title: t('skills'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      name: searchFilterQueryParams.skills,
      options: skills,
      label: t('skills'),
      asyncAutoCompleteProps: {
        maxLength: 100,
        url: Endpoints.App.Common.getSkills,
        normalizer: skillsResponseNormalizer,
        plusButtonClassName: classes.plusButtonClassName,
      },
      placeholder: t('search_skill'),
      getValue: () => getQueryValue(searchFilterQueryParams.skills, 'array'),
      divider: {
        className: classes.groupDivider,
      },
      hiddenInHeader: !getQueryValue(searchFilterQueryParams.skills, 'array')
        ?.length,
    };

    const BENEFITS = {
      formGroup: {
        color: 'smoke_coal',
        title: t('benefits'),
        className: classes.header,
      },
      cp: 'checkBoxGroup',
      classNames: { inputContainer: classes.checkBoxInputContainer },
      name: searchFilterQueryParams.jobBenefits,
      options: benefits,
      label: t('benefits'),
      getValue: () =>
        getQueryValue(searchFilterQueryParams.jobBenefits, 'array'),
      divider: {
        className: classes.groupDivider,
      },
      hiddenInHeader: !getQueryValue(
        searchFilterQueryParams.jobBenefits,
        'array'
      )?.length,
      placeholder: t('search_benefits'),
      asyncAutoCompleteProps: {
        plusButtonClassName: classes.plusButtonClassName,
        maxLength: 100,
        url: lookup.getBenefit,
        normalizer: lookupNormalizer.searchBenefits,
      },
    };

    const filedMapper = {
      people: [
        PAGE_NUMBER,
        {
          name: searchFilterQueryParams.scope,
          formGroup: {
            color: 'smoke_coal',
            title: t('post_type'),
            className: classes.header,
          },
          cp: 'radioGroup',
          options: db.SCOPE,
          label: t('scope'),
          hiddenInHeader: !isBusinessApp,
          hiddenInForm: !isBusinessApp,
          getValue: () => getQueryValue(searchFilterQueryParams.scope) || 'ALL',
        },
        QUERY,
        SEARCH_ENTITY,
        SORT_BY,
        SEARCH_GROUP_TYPE_USER,
        MEMBER_SINCE,
        {
          ...CATEGORY,
          options: industries,
          name: searchFilterQueryParams.industryIds,
          getValue: () =>
            getQueryValue(searchFilterQueryParams.industryIds, 'array'),
          alwaysShowInHeader: true,
        },
        LOCATION,
        LANGUAGES && {
          ...LANGUAGES,
          alwaysShowInHeader: true,
        },
        {
          ...PAGES,
          formGroup: {
            color: 'smoke_coal',
            title: t('related_with'),
            className: classes.header,
          },
          label: t('related_with'),
          hiddenInHeader: false,
          alwaysShowInHeader: true,
          getValue: () =>
            getQueryValue(searchFilterQueryParams.relatedPageIds, 'array'),
          name: searchFilterQueryParams.relatedPageIds,
          options: relatedPages,
        },
        DGREES,
        isBusinessApp && {
          ...PAGES,
          name: searchFilterQueryParams.experiencesAtCompanyIds,
          getValue: () =>
            getQueryValue(
              searchFilterQueryParams.experiencesAtCompanyIds,
              'array'
            ),
        },
        isBusinessApp && SCHOOLS,
      ],
      posts: [
        PAGE_NUMBER,
        QUERY,
        SEARCH_ENTITY,
        SORT_BY,
        {
          ...MEMBER_SINCE,
          formGroup: {
            color: 'smoke_coal',
            title: t('date_posted'),
            className: classes.header,
          },
          options: datePosted,
          name: searchFilterQueryParams.datePosted,
          label: t('date_posted'),
          getValue: () =>
            getQueryValue(searchFilterQueryParams.datePosted) || 'ANY_TIME',
          hiddenInHeader: false,
          alwaysShowInHeader: true,
          isDefaultValue:
            !getQueryValue(searchFilterQueryParams.datePosted) ||
            getQueryValue(searchFilterQueryParams.datePosted) === 'ANY_TIME',
        },
        POST_TYPE,
        LOCATION,
        CREATED_BY && {
          ...CREATED_BY,
          alwaysShowInHeader: true,
          formGroup: {
            color: 'smoke_coal',
            title: t('creator'),
            className: classes.header,
          },
          label: t('creator'),
          name: searchFilterQueryParams.postedByUserIds,
          getValue: () =>
            getQueryValue(searchFilterQueryParams.postedByUserIds, 'array'),
        },
        POSTED_BY,
        MENTIONS,
        HASHTAGS,
      ],
      pages: [
        PAGE_NUMBER,
        QUERY,
        SEARCH_ENTITY,
        SORT_BY,
        SEARCH_GROUP_TYPE_USER,
        JOB_GROUP_STATUS,
        ESTABLISHMENT_DATE,
        {
          ...CATEGORY,
          options: industries,
          name: searchFilterQueryParams.industryIds,
          getValue: () =>
            getQueryValue(searchFilterQueryParams.industryIds, 'array'),
          alwaysShowInHeader: true,
        },
        PAGE_TYPES,
        LOCATION,
        COMPANY_SIZE,
        LANGUAGES,
        { ...HASHTAGS, alwaysShowInHeader: false },
      ],
      jobs: [
        PAGE_NUMBER,
        QUERY,
        SEARCH_ENTITY,
        PLACE_TITLE,
        PLACE_ID,
        isAllFilter && COUNTRYID,
        SEARCH_GROUP_TYPE_USER,
        JOB_GROUP_STATUS,
        SORT_BY,
        DATE_POSTED,
        visibleCategory && CATEGORY,
        EXPERIENCE_LEVEL,
        EMPLOYMENT_TYPE,
        JOB_MODEL,
        TITLES,
        { ...LOCATION, alwaysShowInHeader: false },
        PAGES,
        SALARY,
        SKILLS,
        { ...LANGUAGES, alwaysShowInHeader: false },
        BENEFITS,
        { ...HASHTAGS, alwaysShowInHeader: false },
        isBusinessApp && CREATED_BY,
      ],
    } as any;

    return filedMapper[searchEntity]?.reduce((prev: any, curr: any) => {
      if (!curr || (isBusinessApp && curr.hiddenInBusiness)) {
        return prev;
      }
      return [...prev, curr];
    }, []);
  })();

  return {
    groups,
    groupsObject: collectionToObjectByKey(groups, 'name'),
  };
};

export default useSearchFiltersFields;
