import {
  QueryKeys,
  collectionToObjectByKey,
  getUserNameByObjectId,
  routeNames,
  useHistory,
  useReactQuery,
} from '@shared/utils-pkg';
import useNavigateSearchPage from './useNavigateSearchPage';
import useMedia from '@shared/uikit/utils/useMedia';
import useSearchFilters from './useSearchFilters';
import { searchFilterQueryParams } from '@shared/constants/search';
import useGlobalSearchUtilities from './useGlobalSearchUtilities';
import { usePathname } from 'next/navigation';
import { jobSearchOptions } from '@shared/utils-pkg/constants/enums/jobsDb';
import { useQueryClient } from '@tanstack/react-query';
import { mutableStore } from '@shared/constants/mutableStore';
import { useRef, useState } from 'react';
import { flushSync } from 'react-dom';

const searchEntitiesObject = collectionToObjectByKey(
  jobSearchOptions.searchEntities
);

export function useObjectClicks() {
  const navigateToSearchPage = useNavigateSearchPage();
  const { setFilter, setFilters, refetchWithCurrentEntityId } =
    useSearchFilters();
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const [object, setObject] = useState<{ id: string }>();

  const history = useHistory();
  const { isMoreThanTablet } = useMedia();

  const { refetch: handleTagRedirection } = useReactQuery({
    action: {
      apiFunc: () => getUserNameByObjectId({ objectId: object?.id }),
      key: [QueryKeys.objectDetail, { objectId: object?.id }],
    },
    config: {
      enabled: false,
      onSuccess: (response) => {
        if (response?.username) {
          history.push(`/${response?.username}`);
        } else {
          console.log('Could not get username');
        }
      },
    },
  });

  const handleHashtagClick = (hashtag: string) => {
    if (pathname?.includes(routeNames.searchPosts)) {
      queryClient.invalidateQueries(mutableStore.searchQueryKey);
      setFilters({
        [searchFilterQueryParams.searchEntity]: searchEntitiesObject?.posts,
        hashtags: [hashtag],
      });
    } else {
      navigateToSearchPage({
        pathname: routeNames.searchPosts,
        hashtags: [hashtag],
      });
    }
  };

  const handleTagClick = (
    username: string,
    id: string,
    type: 'people' | 'pages' = 'people',
    otherParams: Record<string, any> = {}
  ) => {
    if (isMoreThanTablet) {
      const isPeople = pathname.includes(routeNames.searchPeople);
      const isPages = pathname.includes(routeNames.searchPages);
      if (isPeople || isPages) {
        setFilters({
          [searchFilterQueryParams.searchEntity]: isPeople
            ? searchEntitiesObject.people
            : searchEntitiesObject.pages,
          [searchFilterQueryParams.currentEntityId]: id,
        });

        refetchWithCurrentEntityId();
      } else {
        navigateToSearchPage({
          pathname:
            type === 'people'
              ? routeNames.searchPeople
              : routeNames.searchPages,
          currentEntityId: id,
          ...otherParams,
        });
      }
    } else {
      if (id) {
        flushSync(() => setObject({ id }));
        handleTagRedirection();
      } else {
        history.push(`/${username}`);
      }
    }
  };
  const handleCardClick = (
    username: string | null,
    id: string,
    variant: ReturnType<typeof useGlobalSearchUtilities>['currentModule'],
    pathnameToNaviagte?: string,
    otherArgs?: any
  ) => {
    if (isMoreThanTablet) {
      if (pathnameToNaviagte) {
        navigateToSearchPage({
          pathname: pathnameToNaviagte,
          currentEntityId: id,
          ...(otherArgs || {}),
        });
        return;
      }
      setFilter(searchFilterQueryParams.currentEntityId, id, true);
    } else {
      if (pathnameToNaviagte) {
        navigateToSearchPage({
          pathname: pathnameToNaviagte,
          currentEntityId: id,
          ...(otherArgs || {}),
        });
        return;
      }
      if (variant === 'posts') return;
      if (variant === 'jobs') history.push(routeNames.jobDetails.makeRoute(id));

      if (id) {
        flushSync(() => setObject({ id }));
        handleTagRedirection();
      } else {
        history.push({
          pathname: `/${username}`,
        });
      }
    }
  };

  return { handleHashtagClick, handleTagClick, handleCardClick };
}
