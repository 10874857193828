export const initialState = {
  isSearchFilterFormOpen: false,
} as any;

type ActionType =
  | { type: 'SET_IS_SEARCH_FILTER_FORM_OPEN'; payload: boolean }
  | { type: 'SET_SEARCH_DYNAMIC_FILTERS'; payload: any };

type StateType = typeof initialState;

export function searchReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_IS_SEARCH_FILTER_FORM_OPEN':
      return {
        ...state,
        isSearchFilterFormOpen: action.payload,
      };
    case 'SET_SEARCH_DYNAMIC_FILTERS':
      return {
        ...state,
        dynamicFilters: action.payload,
      };
    default: {
      throw new Error(`Unsupported action type at Search Reducer`);
    }
  }
}
