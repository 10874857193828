import {
  searchFilterQueryParams,
  searchGroupTypes,
} from '@shared/constants/search';
import {
  QueryKeys,
  jobStatusKeys,
  routeNames,
  useUrlQuery,
} from '@shared/utils-pkg';
import { useQueryClient } from '@tanstack/react-query';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import useDynamicFilters from './useDynamicFilters';
import { usePathname } from 'next/navigation';

const useSearchQueries = () => {
  const query = useUrlQuery();
  const dynamicFilters = useDynamicFilters();
  const queryClient = useQueryClient();
  const pathname = usePathname();

  const searchGroupType =
    query.get(searchFilterQueryParams.searchGroupType) || searchGroupTypes.ALL;
  const occupationId = query.get(searchFilterQueryParams.occupationId);
  const currentEntityId = query.get(searchFilterQueryParams.currentEntityId);
  const isPlaceTitleFiltered = query.get(
    searchFilterQueryParams.isPlaceTitleFiltered
  );
  const isAllFilter = searchGroupType === searchGroupTypes.ALL;
  const isSavedFilter =
    query.get(searchFilterQueryParams.searchGroupType) ===
    searchGroupTypes.SAVED;
  const jobStatus =
    query.getAll(searchFilterQueryParams.status) || jobStatusKeys.open;
  const placeTitle = query.get(searchFilterQueryParams.placeTitle);
  const placeId = query.get(searchFilterQueryParams.placeId);
  const qSalaryPeriod = query.get(searchFilterQueryParams.salaryPeriod);
  const categoryIds = query.getAll(searchFilterQueryParams.categoryIds);
  const searchedQuery = decodeURIComponent(query.get('query') || '');

  const jobTitles = dynamicFilters?.titles || [];
  const creators = dynamicFilters?.creators || [];
  const mentions = dynamicFilters?.mentions || [];
  const pages = dynamicFilters?.pages || [];
  const relatedPages = dynamicFilters?.relatedPages || [];
  const cities = dynamicFilters?.cities || [];
  const industries = dynamicFilters?.industries || [];
  const skills = dynamicFilters?.skills || [];
  const languages = dynamicFilters?.languages || [];
  const categories = useMemo(() => {
    const _categories = dynamicFilters?.categories || [];
    const popularCategories =
      queryClient
        .getQueryData<any>([QueryKeys.jobPopularCategories])
        ?.content?.map((item: any) => ({
          value: item?.id,
          label: item?.title,
          image: item?.imageUrl,
        })) || [];
    const newCategories: any[] = [];
    (categoryIds as string[])?.forEach((id: string) => {
      if (_categories?.includes((_item: any) => _item?.value === id)) return;
      const popular = popularCategories?.find(
        (_item: any) => _item?.value === id
      );
      if (popular) newCategories.push(popular);
    });

    return uniqBy([..._categories, ...newCategories], 'value');
  }, [dynamicFilters, categoryIds, queryClient]);
  const salaryRange = dynamicFilters?.salaryRange || [];
  const benefits = dynamicFilters?.benefits || [];
  const employmentTypes = dynamicFilters?.employmentTypes || [];
  const experienceLevels = dynamicFilters?.experienceLevels || [];
  const workPlaceTypes = dynamicFilters?.workPlaceTypes || [];
  const datePosted = dynamicFilters?.datePosted || [];
  const hashtags = dynamicFilters?.hashtags || [];
  const visibleCategory = categories?.length || categoryIds?.length;
  const companySizes = dynamicFilters?.companySizes;
  const pageTypes = dynamicFilters?.pageTypes;
  const postedByOptions = dynamicFilters?.postedBy;
  const postTypes = dynamicFilters?.postTypes;
  const sortByOptions = dynamicFilters?.sortBy;
  const memberSinceOptions = dynamicFilters?.memberSince;
  const establishmentDateOptions = dynamicFilters?.establishmentDate;
  const isAllSearchGroupType = searchGroupType === searchGroupTypes.ALL;
  const isPosts = useMemo(
    () => [routeNames.searchPosts].some((path) => pathname.includes(path)),
    [pathname]
  );

  function getQueryValue(
    key: (typeof searchFilterQueryParams)[keyof typeof searchFilterQueryParams],
    type: 'string' | 'array' = 'string'
  ) {
    return type === 'string'
      ? query.get(key)
      : getNormalizedArrayQuery(query, key);
  }

  return {
    searchGroupType,
    occupationId,
    currentEntityId,
    isPlaceTitleFiltered,
    isAllFilter,
    isSavedFilter,
    jobStatus,
    placeTitle,
    placeId,
    qSalaryPeriod,
    categoryIds,
    jobTitles,
    creators,
    pages,
    relatedPages,
    cities,
    industries,
    skills,
    languages,
    categories,
    salaryRange,
    benefits,
    employmentTypes,
    experienceLevels,
    workPlaceTypes,
    datePosted,
    hashtags,
    visibleCategory,
    companySizes,
    pageTypes,
    isAllSearchGroupType,
    searchedQuery,
    isPosts,
    postedByOptions,
    postTypes,
    sortByOptions,
    memberSinceOptions,
    establishmentDateOptions,
    mentions,
    getQueryValue,
  };
};

export default useSearchQueries;

function getNormalizedArrayQuery(query: any, queryName: string) {
  return (
    query
      .getAll(queryName)
      ?.map((item: any) => item?.split(/,(?!\s)/))
      // split by "," but not when it's ", "
      ?.flat()
      ?.filter((item: any) => Boolean(item))
  );
}
